import React from 'react'
import { Button, Stack } from '@mui/material'
import {
  Add,
  FileDownload,
  FileUpload,
  PictureAsPdf
} from '@mui/icons-material'

import { useTranslation } from '@contexts/translation'

import FilterActionsSelector from '@components/common/Filter/ActionsSelector'
import downloadPDF from '@utils/downloadPDF'
import { useAuth } from '@contexts/auth'
import downloadXLS from '@utils/downloadXLS'

const HeaderActions = ({
  downloadData,
  actionsProps = {},
  permissions = {}
}) => {
  const { dispatchActionsModal } = actionsProps
  const { manageInvoices } = permissions

  const { t } = useTranslation()
  const { teamSettings, membership } = useAuth()

  return (
    <>
      {manageInvoices && (
        <Stack direction="row" alignItems="center" gap={2}>
          {!membership.is_readonly && (
            <Button
              startIcon={<Add />}
              sx={{ height: '30px' }}
              data-cy={'add-btn'}
              onClick={() => dispatchActionsModal({ type: 'add' })}
              data-testid="Button-5eaf97f0-3081-4d4c-92ea-7f5c67f38499"
            >
              {t('payment')}
            </Button>
          )}

          <FilterActionsSelector
            isMenu
            options={[
              {
                label: t('actions'),
                handleClick: () => null
              },
              {
                icon: <FileUpload color="secondary" />,
                label: t('import'),
                handleClick: () => {}
              },
              {
                icon: <FileDownload color="secondary" />,
                label: t('export_xls'),
                disabled: !downloadData?.length,
                handleClick: () => downloadXLS(downloadData, t('payments'))
              },
              {
                icon: <PictureAsPdf color="secondary" />,
                label: t('export_pdf'),
                disabled: !downloadData?.length,
                handleClick: () =>
                  downloadPDF(downloadData, t('payments'), teamSettings?.name)
              }
            ]}
          />
        </Stack>
      )}
    </>
  )
}

export default HeaderActions
