import React from 'react'
import { mutate } from 'swr'
import { format } from 'date-fns'
import { useSnackbar } from 'notistack'
import {
  Grid,
  Stack,
  MenuItem,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
  CircularProgress
} from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

import CommonPopoverForm from '@components/common/PopoverForm/CommonPopoverForm'

import unformatDate from '@utils/unformatDate'
import numberWithCommas from '@utils/numberWithCommas'
import API_URL from '@config/services'
import { useTranslation } from '@contexts/translation'
import { updatePayment, updatePaymentInvoice } from '@api/payments'
import { useFetchData } from '@api/fetchData'

import useTableStyles from '@styles/table/table'
import CommonModal from '@components/common/Modal/CommonModal'
import { responseHandler } from '@utils/responseHandler'

const MatchInvoicesModal = ({
  handleClose,
  paymentId,
  open,
  fetchPaymentsURL,
  currency
}) => {
  const classesTable = useTableStyles()

  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const paymentURL = paymentId
    ? API_URL.PAYMENTS.GET_PAYMENT_PK(paymentId)
    : null
  const { data: paymentData, isLoading } = useFetchData(paymentURL)

  const PAYMENT_TYPES = [
    {
      id: 1,
      label: dictionary.cash,
      value: 'E'
    },
    {
      id: 2,
      label: dictionary.check,
      value: 'C'
    },
    {
      id: 3,
      label: dictionary.card,
      value: 'U'
    },
    {
      id: 4,
      label: dictionary.lcr,
      value: 'L'
    },
    {
      id: 5,
      label: dictionary.transfer,
      value: 'V'
    },
    {
      id: 6,
      label: dictionary.deposit,
      value: 'P'
    },
    {
      id: 7,
      label: dictionary.none,
      value: 'S'
    }
  ]

  const handleUpdatePaymentField = async (field, value) => {
    const response = await updatePayment(paymentData.pk, { [field]: value })

    responseHandler({
      response,
      callback: async () => {
        await mutate(paymentURL)
        await mutate(fetchPaymentsURL)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.payment_updated,
      snackbar: enqueueSnackbar
    })
  }

  const handleUpdatePaymentInvoiceField = async (invoice_id, field, value) => {
    const response = await updatePaymentInvoice(invoice_id, { [field]: value })

    responseHandler({
      response,
      callback: async () => {
        await mutate(paymentURL)
        await mutate(fetchPaymentsURL)
      },
      dictionary,
      msgSuccess: dictionary.Invoicing.invoice_updated,
      snackbar: enqueueSnackbar
    })
  }

  const handleChangePaymentDate = values => {
    const formatedDate = format(values.calendarPopover, 'dd-MM-yyyy')

    handleUpdatePaymentField('date', formatedDate)
  }

  const getTotalAmount = arr => {
    return arr?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.invoice.amount,
      0
    )
  }

  const getTotalBalance = arr => {
    return arr?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.invoice.balance,
      0
    )
  }

  const getTotalPayment = arr => {
    return arr?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0
    )
  }

  const getDiffNumber = () => {
    return paymentData.amount - getTotalPayment(paymentData.invoice_payments)
  }

  return (
    <CommonModal
      open={open}
      title={paymentData?.display}
      onClose={handleClose}
      maxWidth={'md'}
    >
      {isLoading && (
        <Grid item xs={12} align="center">
          <CircularProgress color="secondary" sx={{ m: 10 }} />
        </Grid>
      )}

      {!isLoading && paymentData && (
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ whiteSpace: 'nowrap' }}>
            <Stack direction="row" spacing={1} sx={{ width: 'min-content' }}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                data-testid="Typography-4d91ec3f-3156-4213-aef4-da54dda6e263"
              >
                {dictionary.date}
              </Typography>
              <span data-testid="span-53b72d58-f4bd-4016-b18a-0f5aa0e51677">
                <CommonPopoverForm
                  variant={'changeOption'}
                  buttonLabel={paymentData.date}
                  propertyLabel={dictionary.date}
                  type="calendar"
                  defaultValue={unformatDate(paymentData.date)}
                  onSubmit={handleChangePaymentDate}
                  data_testid="CommonPopoverForm-d1292ef2-726a-43c5-9614-a86ea013f0e0"
                />
              </span>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: 'min-content' }}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                data-testid="Typography-5bfe2947-63d8-4802-b203-939eb3af4240"
              >
                {dictionary.type}
              </Typography>
              <span data-testid="span-1d569efa-1597-41db-ab04-b9e3549e9968">
                <CommonPopoverForm
                  variant={'changeOption'}
                  buttonLabel={paymentData.type_display}
                  propertyLabel={dictionary.type}
                  type="select"
                  defaultValue={paymentData.type}
                  onSubmit={values =>
                    handleUpdatePaymentField('type', values.selectPopover)
                  }
                  data_testid="CommonPopoverForm-918c833a-74bd-4a8d-981a-67691ef6c81b"
                >
                  {PAYMENT_TYPES.map(({ id, label, value }) => (
                    <MenuItem
                      value={value}
                      key={id}
                      data-testid="MenuItem-3330576f-2296-4436-ab03-f00a253bdabc"
                    >
                      {label}
                    </MenuItem>
                  ))}
                </CommonPopoverForm>
              </span>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: 'min-content' }}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                data-testid="Typography-18b5b034-18e0-4ed8-8935-a5ab69d0fccd"
              >
                {dictionary.payment}
              </Typography>
              <span data-testid="span-6ab4b657-f94a-4918-a57e-a3434f0d855b">
                <CommonPopoverForm
                  variant={'changeOption'}
                  buttonLabel={numberWithCommas(paymentData.amount, 2)}
                  propertyLabel={dictionary.payment}
                  type="number"
                  defaultValue={paymentData.amount}
                  onSubmit={values =>
                    handleUpdatePaymentField(
                      'amount',
                      Number(values.textPopover)
                    )
                  }
                  data_testid="CommonPopoverForm-664914ff-4b7c-45ad-9635-c466e5b1954a"
                />
              </span>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: 'min-content' }}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                data-testid="Typography-eecf94dd-0206-4e1b-9b43-29eca1c67bfa"
              >
                {dictionary.currency_rate}
              </Typography>
              <span data-testid="span-6bfb18c4-6832-4f4f-a3c5-9803ef447e99">
                <CommonPopoverForm
                  variant={'changeOption'}
                  buttonLabel={paymentData.currency_rate}
                  propertyLabel={dictionary.currency_rate}
                  type="number"
                  defaultValue={paymentData.currency_rate}
                  onSubmit={values =>
                    handleUpdatePaymentField(
                      'currency_rate',
                      Number(values.textPopover)
                    )
                  }
                  data_testid="CommonPopoverForm-eb5ef8a7-9dae-4dd9-a980-b6d5043d2021"
                />
              </span>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: 'min-content' }}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                data-testid="Typography-980c18e0-8176-44a9-9d2b-4432e8a19f25"
              >
                {dictionary.local_amount}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.light"
                data-testid="Typography-5cf2f15a-618e-4a3a-ac7f-62050301de6d"
              >
                {numberWithCommas(paymentData.amount_ref, 2)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ width: 'min-content' }}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                data-testid="Typography-94928e3d-6be0-4569-afa5-78d7ca39d8ca"
              >
                {dictionary.currency}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.light"
                data-testid="Typography-e334b839-02a9-4462-b2e9-ba2b043cc487"
              >
                {paymentData.currency_display}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  data-testid="Typography-441438aa-327e-487b-94f6-306a30ba4b71"
                >
                  {dictionary.invoices}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer className={classesTable.container}>
                  <Table>
                    <TableHead>
                      <TableRow hover={false}>
                        <TableCell className={classesTable.headerCell}>
                          {dictionary.invoice}
                        </TableCell>
                        <TableCell className={classesTable.headerCell}>
                          {dictionary.due_date}
                        </TableCell>
                        <TableCell
                          className={classesTable.headerCell}
                          align="right"
                        >
                          {dictionary.amount_after_tax}
                        </TableCell>
                        <TableCell
                          className={classesTable.headerCell}
                          align="right"
                        >
                          {dictionary.balance}
                        </TableCell>
                        <TableCell />
                        <TableCell
                          className={classesTable.headerCell}
                          align="right"
                        >
                          {dictionary.payment}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentData.invoice_payments?.map(invoice_payment => (
                        <TableRow
                          key={invoice_payment.pk}
                          className={
                            invoice_payment.invoice.balance === 0
                              ? classesTable.rowTotalSecondary
                              : classesTable.row
                          }
                        >
                          <TableCell>{invoice_payment.invoice.code}</TableCell>
                          <TableCell>
                            {invoice_payment.invoice.due_date}
                          </TableCell>
                          <TableCell align="right">
                            {numberWithCommas(
                              invoice_payment.invoice.amount,
                              2,
                              currency
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {numberWithCommas(
                              invoice_payment.invoice.balance,
                              2,
                              currency
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleUpdatePaymentInvoiceField(
                                  invoice_payment.pk,
                                  'amount',
                                  invoice_payment.invoice.balance
                                )
                              }
                              data-testid="IconButton-e35faa85-a4aa-464c-b643-7dd8b61ae519"
                            >
                              <ArrowForward fontSize="small" />
                            </IconButton>
                          </TableCell>
                          <TableCell align="right">
                            <CommonPopoverForm
                              buttonLabel={
                                <Typography
                                  variant="changeOptionDark"
                                  data-testid="Typography-d189dba9-6da8-4b82-8f24-efc185f4479d"
                                >
                                  {numberWithCommas(
                                    invoice_payment.amount,
                                    2,
                                    currency
                                  )}
                                </Typography>
                              }
                              propertyLabel={dictionary.payment}
                              type="number"
                              defaultValue={invoice_payment.amount || 0}
                              onSubmit={values =>
                                handleUpdatePaymentInvoiceField(
                                  invoice_payment.pk,
                                  'amount',
                                  Number(values.textPopover)
                                )
                              }
                              data_testid="CommonPopoverForm-4bd4daaf-3094-4c68-b99e-049a86a30bb2"
                            />
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow hover={false} className={classesTable.rowTotal}>
                        <TableCell align="right" colSpan={3}>
                          <Typography
                            variant="subtitle2"
                            color="secondary.main"
                            data-testid="Typography-3d86eb2d-c881-4391-93af-f3bc44b9f9d2"
                          >
                            {numberWithCommas(
                              getTotalAmount(paymentData.invoice_payments),
                              2,
                              currency
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            color="secondary.main"
                            data-testid="Typography-8c077493-d27a-49df-a651-929ce5514c59"
                          >
                            {numberWithCommas(
                              getTotalBalance(paymentData.invoice_payments),
                              2,
                              currency
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            color="secondary.main"
                            data-testid="Typography-2599a25a-d2c0-49d9-aec3-3b76f41cf31a"
                          >
                            {numberWithCommas(
                              getTotalPayment(paymentData.invoice_payments),
                              2,
                              currency
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={8} />
              <Grid item xs={4}>
                <TableContainer className={classesTable.container}>
                  <Table>
                    <TableBody>
                      <TableRow hover={false} className={classesTable.row}>
                        <TableCell>{dictionary.diff}</TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            color={
                              getDiffNumber() > 0 ? 'highlight.red' : 'inherit'
                            }
                            data-testid="Typography-a067a4f1-142a-4b7f-bd23-425e4d7b6646"
                          >
                            {numberWithCommas(getDiffNumber(), 2, currency)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow hover={false} className={classesTable.rowTotal}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            data-testid="Typography-1f91884c-68ae-48ef-b36b-3c251714f654"
                          >
                            {dictionary.payment}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            color="secondary.main"
                            data-testid="Typography-5435ca81-9463-462f-8eca-728e82863263"
                          >
                            {numberWithCommas(paymentData.amount, 2, currency)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CommonModal>
  )
}

export default MatchInvoicesModal
